import { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { LocaleContext } from '../../contexts';

export const useGlobalDictionaryQuery = () => {
  const { lang } = useContext(LocaleContext);

  const {
    allContentfulGlobalDictionary: { edges },
    allContentfulGlobalDictionaryTwo: { edges: edgesTwo },
    allContentfulGlobalDictionaryThree: { edges: edgesThree },
    allContentfulGlobalDictionaryFour: { edges: edgesFour },
  } = useStaticQuery(
    graphql`
      query dictionary {
        allContentfulGlobalDictionary {
          edges {
            node {
              node_locale
              and
              delivery
              description
              details
              dimensions
              emailAddress
              password
              emailSubscribeSuccessMsg
              emailSubscribeFailMsg
              emailSubscribeAlreadyInListMsg
              explore
              hashLinkFooter
              youMayAlsoLike
              myAddress
              myOrders
              newAddress
              noPurchaseMessage
              productDeliveryReturnsInfo {
                json
              }
              landingSubscribeText {
                json
              }
              quickDelivery
              shippingMessageCart
              shippingMessageTopBar
              slowDelivery
              subscribeButton
              accountReturnAlreadyRefunded
              accountReturnPeriodExpired
              accountReturnSuccess
              accountReturnFail
              account
              myAccount
              createAnAccount
              send
              loading
              login
              logOut
              forgotMyPassword
              cartText
              closeCartText
              goToCheckoutText
              deleteFromCartText
              cartQuantityText
              addToCartText
              pdpSizeText
              pdpQuantityText
              pdpSaleText
              discover
              discoverOur
              newsletterText
            }
          }
        }
        allContentfulGlobalDictionaryTwo {
          edges {
            node {
              node_locale
              wrongEmailOrPassword
              problemsWithLogout
              forgotPassword
              forgotPasswordDescription
              loginIntoMyAccount
              firstName
              lastName
              register
              confirmPassword
              alreadyHaveAnAccount
              activateYourAccount
              address
              zipCode
              city
              phoneNumber
              country
              price
              rooms
              colors
              collections
              close
              apply
              filters
              allProducts
              discoverAllProducts
              seeMoreProducts
              pleaseFindHereAllYourOrders
              previousAndCurrentAddress
              noAddressSavedYet
              addNewAddressHere
              pleaseFillHereYourInformations
              pleaseEnterAValidEmailAddress
              pleaseEnterOnlyLatinLetters
              atLeastNCharactersWithNCapitalLetter
              passwordRequirements
              passwordsDoNotMatch
              allSetThanks
              back
              productReviews
              productReviewsHeading
              addProtectionText
              addProtectionDescription
              addProtectionPrice
              subscribe
              newsletterSubscribeButtonText
              stayInformed
              outOfStock
              quickestDelivery
              skyltDelivery
            }
          }
        }
        allContentfulGlobalDictionaryThree {
          edges {
            node {
              node_locale
              helpDeskContact
              helpDeskContactDbodhi
              helpDeskClose
              helpDeskChatWithUs
              helpDeskCallUs
              helpDeskWeAreOnline
              helpDeskWeAreOffline
              helpDeskWeAreAvailable
              helpDeskWeAreUnavailable
              helpDeskWeAreAvailablePhoneCall
              helpDeskWeAreUnavailablePhoneCall
              helpDeskWhatsappText
              helpDeskSendUsAMessage
              helpDeskResponseTime
              helpDeskInstagramButtonText
              helpDeskInstagramResponseTime
            }
          }
        }
        allContentfulGlobalDictionaryFour {
          edges {
            node {
              node_locale
              notifyWhenBackInStock
              submit
              loading
              backInStockSuccessfulSubmission
              backInStockFailedSubmission
              deliveryWhenStatusInStockAndAddProtection
              titleFilterStock
              imageForForm {
                fluid {
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                }
              }
              colorButtonTabContact
              textColorButtonTabContact
              informationFormOutlet
              titleFormOutlet
              textButtonFormOutlet
              backgroundSidebarContact
              colorButtonInSidebarContact
              colorDescriptionTitleInContactSidebar
              colorMenuContactInSidebar
              colorBackgroundNewsletterPopup
              colorIconCloseNewsletterPopup
              colorTitleDiscountNewsletterPopup
              colorDescriptionNewsletterPopup
              colorTextInputNewsletterPopup
              colorBackgroundButtonNewsletterPopup
              colorTextButtonNewsletterPopup
              colorBorderButtonNewsletterPopup
              colorBackgroundButtonNewsletterPopupHover
              colorTextButtonNewsletterPopupHover
              colorBorderButtonNewsletterPopupHover
              extraProduct {
                title
                titleToShow
                protection
                slug
                protectionExtraItem
                thumbnailImages {
                  fluid {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                  }
                }
                shopifyData {
                  variants {
                    price
                    compare_at_price
                    title
                    id
                    product_id
                    sku
                    inventory_quantity
                  }
                }
              }
              homepageHeroVidioDesktop {
                file {
                  url
                  fileName
                  contentType
                }
              }
              homepageHeroVidioMobile {
                file {
                  url
                  fileName
                  contentType
                }
              }
            }
          }
        }
      }
    `
  );

  const combinedEdges = [...edges, ...edgesTwo, ...edgesThree, ...edgesFour];
  const filteredDictionaryQuery = combinedEdges.filter(
    ({ node: { node_locale } }) => node_locale === lang
  );

  const dictionary = filteredDictionaryQuery.reduce((acc, val) => ({ ...acc, ...val.node }), {});

  return dictionary;
};
